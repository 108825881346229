<script>
import PavilionForm from "@/views/menu/pavilion/pavilionForm";
import Component, { mixins } from "vue-class-component";

@Component({
  inject: {
    pavilionService: "pavilionService",
    toastService: "toastService",
  },
})
export default class PavilionNew extends mixins(PavilionForm) {
  formId = "new-pavilion-form";

  get fieldColumns() {
    return 2;
  }
  get actions() {
    return [
      {
        id: "save",
        type: "submit",
        text: this.translations.labels.button_save,
        leftIcon: "mdi-content-save-outline",
        handle: this.save,
      },
    ];
  }
  async save(action, passesValidation) {
    this.errors = [];
    if (!passesValidation) {
      return;
    }
    try {
      await this.pavilionService.create({
        idArea: this.model.idArea,
        idInfoBox: this.model.idInfoBox,
        code: this.model.code,
        layout: this.model.layout,
        cardWithDescription: this.model.cardWithDescription,
        visualizeTagOnCards: this.model.visualizeTagOnCards,
        order: this.model.order,
        localizations: this.model.localizations,
        taxonomiesIds: this.model.taxonomiesIds
      });
      this.$router.push("/pavilion/list");
      this.toastService.success(this.translations.success.pavilion_create);
    } catch (error) {
      this.errors = this.formatApiErrors(error);
    }
  }

  getFormTitle() {
    return this.translations.pageTitles.pavilions_new;
  }

  afterCreate () {
    this.model = {
      idArea: "",
      code: "",
      layout: "",
      cardWithDescription: false,
      visualizeTagOnCards: false,
      order: 0,
      taxonomiesIds: [],
      localizations: []
    };

    this.isReady = true;
  }
}
</script>
